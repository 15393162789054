export function inTime(lastOrderTime, currentTime) {
    const lastOrderTimeDate = new Date(lastOrderTime);
    return lastOrderTimeDate >= currentTime
}

export function dateDeliveryStatus(lastOrderTime, currentTime) {
    if (inTime(lastOrderTime, currentTime)) {
        return "table-success"
    }
    return "table-danger"
}

export function storeDeliveryStatus(store, currentTime) {
    if (inTime(new Date(store.lastOrderTime), currentTime)) {
        return "table-success"
    } else if(inTime(new Date(store.lastOrderTimePremium), currentTime)) {
        return "table-warning"
    }
    return "table-danger"
}

function deliveryStateToNumber(state) {
    switch (state) {
        case "table-success":
            return 0
            break
        case "table-warning":
            return 10
            break
        case "table-danger":
            return 20
            break
    }
}


function sortDeliveryStatus(a, b) {
    if (a.deliveryStatus < b.deliveryStatus ) {
        return 1
    }
    if (a.deliveryStatus > b.deliveryStatus) {
        return -1
    }

    // If equal Status

    if (a.name > b.name) {
        return 1
    }
    if (a.name < b.name) {
        return -1
    }

}


export function sortByStoreDeliveryStatus(stores, currentTime) {
    stores.forEach(element => {
        element.deliveryStatus = deliveryStateToNumber(storeDeliveryStatus(element, currentTime))
    })

    return stores.sort(sortDeliveryStatus)

}