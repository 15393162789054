import * as React from 'react'
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import SidebarLayout from "./sidebar";
import { Helmet } from "react-helmet"

export default function DefaultLayout({children, isIndex = false}) {

    return (
        <div className="" style={{backgroundImage: 'url(/images/christmas-tress-high-res_glossy.png)', backgroundSize: '200px'}}>
            <Helmet
                htmlAttributes={{
                    lang: 'de',
                }}
            />

            <NavBar></NavBar>
            {isIndex && <div className="p-5"/>}
                <SidebarLayout>
                    {children}
                </SidebarLayout>
            <Footer></Footer>
        </div>
    )
}
