import * as React from 'react'
import FrontPageSidebar from "../components/frontpage-sidebar";

export default function SidebarLayout({children}) {

    return (
            <div className="container my-4">
                <div className="row">
                    <div className="col-xl-7 col-lg-8 col-md-12 bg-white py-4">
                        {children}
                    </div>
                    <div className="col-xl5 col-lg-4 col-md-12 bg-white ms-lg-4 py-4">
                        <FrontPageSidebar ></FrontPageSidebar>
                    </div>
                </div>
            </div>
    )
}
